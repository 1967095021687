import React from 'react';
import { st, classes } from './CommentsCounter.st.css';
import { Text } from 'wix-ui-tpa';
import CommentIcon from './comment.svg';

export const CommentsCounter = ({
  commentsCount,
  isMobile,
}: {
  commentsCount: number;
  isMobile?: boolean;
}) => {
  const shown = commentsCount > 0;
  // container is still shown so than ssr hydration wont fail
  return (
    <div
      id="commentsCounter"
      className={st(classes.root, { mobile: !!isMobile, hidden: !shown })}
    >
      {shown && (
        <>
          <CommentIcon className={classes.icon} />
          <Text className={classes.count}>{commentsCount}</Text>
        </>
      )}
    </div>
  );
};
