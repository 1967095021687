import react, { useState, useEffect } from 'react';
import { Actions } from '../../components/Collection/types';
import { ActionType } from '../../consts/collectionsConsts';

// Actions[keyof Actions]
export default class ActionDeferred {
  fullfilled: boolean;
  promise: Promise<void>;
  reject?: () => void;
  resolve?: (data?: any) => void;
  constructor(callback?: () => void) {
    this.fullfilled = false;
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = (...args) => {
        this.fullfilled = true;
        resolve(...args);
      };
    });
    if (callback) {
      this.promise.then(callback);
    }
  }
}

export const useWorkerPromise = ({
  actions,
  promiseResult,
  promiseResultData,
}: {
  actions: Actions;
  promiseResult: string;
  promiseResultData?: any;
}) => {
  const [workerPromises, setWorkerPromises] = useState(
    {} as { [actionName: string]: ActionDeferred },
  );

  // when action triggered a new actionDeffered is cread an
  const promisifiedActions = { ...actions };

  Object.values(ActionType).forEach((actionName: keyof typeof ActionType) => {
    if (actions[actionName]) {
      promisifiedActions[actionName] = (params?: string) => {
        const promiseDefered = new ActionDeferred();
        setWorkerPromises({ ...workerPromises, [actionName]: promiseDefered });
        if (actions[actionName] && typeof actions[actionName] === 'function') {
          // @ts-expect-error
          actions[actionName](params);
        }
        return promiseDefered.promise;
      };
    }
  });

  useEffect(() => {
    if (promiseResult) {
      const arr = promiseResult.split('_');
      let actionName = '';
      let result = '';
      if (arr.length === 3) {
        actionName = arr[0];
        result = arr[1];
      }
      if (arr.length === 4) {
        actionName = `${arr[0]}_${arr[1]}`;
        result = arr[2];
      }
      const promise = workerPromises[actionName];
      if (promise) {
        if (result === 'resolved') {
          promise.resolve && promise.resolve(promiseResultData);
        }
        if (result === 'rejected') {
          promise.reject && promise.reject();
        }
      }
    }
  }, [promiseResult]);

  return promisifiedActions;
};
