import React from 'react';
import { CollectionInfoModal } from '../../../../../common/components/modals/CollectionInfo/CollectionInfoModal';
import { LoveActions } from '../../../../../common/LoveService';

type GalleryCreateListModalProps = {
  compId: string;
  biDefaultParamsForLove: object;
  onClose: () => void;
  loveActions: LoveActions;
  currentItemId: string;
};

export const GalleryCreateListModal = ({
  compId,
  biDefaultParamsForLove,
  onClose,
  loveActions,
  currentItemId,
}: GalleryCreateListModalProps) => {
  return (
    <div className={compId}>
      <CollectionInfoModal
        onClose={onClose}
        onSave={async ({
          name,
          privacy_settings,
          description,
        }: CollectionDataForSave) => {
          // onClose();
          loveActions.createCollectionAndLove(
            currentItemId,
            JSON.stringify({
              name,
              privacySettings: privacy_settings,
              description,
            }),
            biDefaultParamsForLove,
          );
          onClose();
        }}
        isCreateCollection={true}
        biDefaultParams={biDefaultParamsForLove}
      />
    </div>
  );
};
