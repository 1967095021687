import React, { useState, useEffect, useCallback } from 'react';
import { MobileDrawer, ActionsMenuLayout, Text } from 'wix-ui-tpa';
import { LoveActions } from '../../../../../../common/LoveService';
import { useBi } from '@wix/yoshi-flow-editor';
import { ItemCollection } from './ItemColletion';
import { st, classes } from '../AddToListPopover.st.css';
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall';
import Plus from 'wix-ui-icons-common/on-stage/Plus';

type ItemCollectionsListMobileProps = {
  itemCollections: ItemMediaCollection[];
  hoveredItemId: string;
  setHovered: (id: string) => void;
  onClose: () => void;
  onCreate: () => void;
  loveActions: LoveActions;
  t: (key: string) => string;
  currentItemId: string;
  navigator: INavigator;
  biDefaultParams: object;
};

export const ItemCollectionsListMobile = ({
  itemCollections,
  hoveredItemId,
  setHovered,
  onClose,
  loveActions,
  currentItemId,
  t,
  navigator,
  biDefaultParams,
  onCreate,
}: ItemCollectionsListMobileProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  return (
    <MobileDrawer
      isOpen={isModalOpen}
      onRequestClose={() => onClose()}
      className={classes.mobilePopoverContainerWrapper}
    >
      <div style={{ width: '100%' }} className={classes.mobilePopoverContainer}>
        <div className={classes.headerMobile}>
          <Text className={classes.title}>
            {t('GALLERY_ADD_TO_LIST_MODAL_TITLE')}
          </Text>
          <Text className={classes.subtitle}>
            {t('GALLERY_ADD_TO_LIST_MODAL_SUBTITLE')}
          </Text>
        </div>
        <div className={classes.listsMobile}>
          {itemCollections &&
            itemCollections.map((collection, idx) => {
              const isHovered = hoveredItemId === collection.mediaCollectionId;
              const isInCollection = collection.isItemInCollection;
              return (
                <ItemCollection
                  setHovered={setHovered}
                  isHovered={isHovered}
                  collection={collection}
                  isInCollection={isInCollection}
                  onClose={onClose}
                  loveActions={loveActions}
                  t={t}
                  biDefaultParams={biDefaultParams}
                  currentItemId={currentItemId}
                  navigator={navigator}
                  isMobile={true}
                />
              );
            })}
        </div>
        <div className={classes.footerMobile}>
          <ActionsMenuLayout className={classes.actionMenu}>
            <ActionsMenuLayout.Divider />
            <ActionsMenuLayout.Item
              onClick={() => onCreate()}
              content={t('COLLETION_POPOVER_CREATE_LIST_MOBILE')}
              prefixIcon={<Plus />}
            />
            <ActionsMenuLayout.Divider />
            <ActionsMenuLayout.Item
              onClick={() => onClose()}
              content={t('COLLETION_POPOVER_CLOSE_MOBILE')}
              prefixIcon={<CloseSmall />}
            />
          </ActionsMenuLayout>
        </div>
      </div>
    </MobileDrawer>
  );
};
