import { st, classes } from './ItemHover.st.css';
import React from 'react';
import { ActionsMenuLayout, Popover } from 'wix-ui-tpa';
import cx from 'classnames';

type Option = {
  content: string;
  onClick: () => void;
};

type ItemMenuProps = {
  isMenuOpen?: boolean;
  closeMenu: () => void;
  dotsElement: React.Component;
  menuOptions: Option[];
  compId: string;
  isHorizontal: boolean;
};

export const withItemMenu = ({
  isMenuOpen = false,
  closeMenu,
  dotsElement,
  menuOptions,
  compId,
  isHorizontal,
}: ItemMenuProps) => {
  return (
    <Popover
      shown={isMenuOpen}
      placement="bottom-end"
      showArrow={false}
      appendTo="window"
      contentClassName={cx(classes.menuContainer, compId)}
      moveBy={isHorizontal ? { x: -227, y: 0 } : { x: -13, y: -137 }}
    >
      <Popover.Element>
        {/* @ts-expect-error */}
        <div className={classes.dots}>{dotsElement}</div>
      </Popover.Element>
      <Popover.Content>
        <ActionsMenuLayout className={classes.menu}>
          {menuOptions.map((option) => (
            <ActionsMenuLayout.Item
              content={option.content}
              onClick={option.onClick}
            />
          ))}
        </ActionsMenuLayout>
      </Popover.Content>
    </Popover>
  );
};
