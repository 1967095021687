import React, { useState } from 'react';
import { MEDIA_COLLECTIONS } from '../../../../consts/photographyConsts.ts';
import { GALLERY_TYPES } from '../../../../common/helpers/galleryTypes.ts';
import { CommonStoreWrapper } from '@wix/common-pro-gallery-component-wrapper';
import './styles/commonDynamicCss.scss';
import './styles/commonStoreStaticsCss.global.scss';
import './styles/FullscreenWrapperWixStyles.scss';
import '../../../../styles/artstorePagesWixStyles.global.scss';
import './styles/collectionsDynamicStyles.scss';
import { ShopSideBarWrapper } from './ShopSideBarWrapper';
import { CommentsCounter } from '../../../../common/components/CommentsCounter/CommentsCounter';
import { ItemHover } from './ItemHover/ItemHover';
import {
  LOVE_BUTTON_ACTION,
  AddToListPopoverOrigin,
} from '../../../../consts/collectionsConsts';

export default class ArtStoreGalleryWrapper extends CommonStoreWrapper {
  constructor(props) {
    super(props);
    this.isCollection = this.isCollection.bind(this);
    this.hoverRendererWithFavoritesList =
      this.hoverRendererWithFavoritesList.bind(this);
    this.infoRendererWithFavoritesList =
      this.infoRendererWithFavoritesList.bind(this);
    this.slideshowInfoRendererrWithFavoritesList =
      this.slideshowInfoRendererrWithFavoritesList.bind(this);
    this.getFullscreenCustomComponents =
      this.getFullscreenCustomComponents.bind(this);
    this.isFavoriteListsLoveButton =
      props.galleryType === GALLERY_TYPES.SET &&
      props.loveButtonAction === LOVE_BUTTON_ACTION.FAVORITE_LISTS;
    if (this.isFavoriteListsLoveButton) {
      this.itemActionsHelper.newItemLoveDataArrived = (itemsLoveData) => {
        itemsLoveData &&
          this.setState({
            itemsLoveData,
          });
      };
    }
  }

  getSentryDSN() {
    return MEDIA_COLLECTIONS.SENTRY_DSN;
  }

  getCustomIconWrapperFunc() {
    if (this.isFreeArtStore()) {
      return () => <div />;
    }
    return undefined;
  }

  isCollection() {
    const { galleryType } = this.props;
    return galleryType === GALLERY_TYPES.LIST;
  }

  isFreeArtStore() {
    return this.props?.additionalProviderParams?.freeArtStore;
  }

  shouldUseGalleryIdForShareUrl() {
    return true;
  }

  getFullscreenCustomComponents() {
    return (
      this.props.fullscreenCustomLoveCounter && {
        customLoveCounterRenderer: (id) =>
          this.props.fullscreenCustomLoveCounter(
            id,
            AddToListPopoverOrigin.FULLSCREEN,
          ),
      }
    );
  }

  collectionsHoverInfoElement = (pgItemProps) => {
    this.itemsHelper.fetchItemMetadata(pgItemProps);

    const InfoElement = this.itemsHelper.infoElement;

    const itemComments =
      this.props.comments && this.props.comments[pgItemProps.id];

    const elem = this.props.isMobile ? (
      <CommentsCounter commentsCount={itemComments?.total} />
    ) : (
      InfoElement && (
        <ItemHover
          InfoElement={InfoElement}
          commentsCount={itemComments?.total}
          processPgItemPropsForInfoElement={
            this.itemsHelper.processPgItemPropsForInfoElement
          }
          pgItemProps={pgItemProps}
          collectionId={this.props.galleryId}
          removeItemAndUpdateState={this.props.removeItemAndUpdateState}
          locked={this.props.locked}
          galleryActionPrecondition={this.props.galleryActionPrecondition}
          compId={this.props.host.id}
        />
      )
    );
    return elem;
  };

  hoverRendererWithFavoritesList(props) {
    return this.itemsHelper.hoverInfoElement({
      ...props,
      customLoveCounter: this.props.customLoveCounter(
        props.photoId,
        AddToListPopoverOrigin.GALLERY,
      ),
    });
  }

  infoRendererWithFavoritesList(props) {
    return this.itemsHelper.externalInfoElement({
      ...props,
      customLoveCounter: this.props.customLoveCounter(
        props.photoId,
        AddToListPopoverOrigin.GALLERY,
      ),
    });
  }

  slideshowInfoRendererrWithFavoritesList(props) {
    return this.itemsHelper.slideshowInfoElement({
      ...props,
      customLoveCounter: this.props.customLoveCounter(
        props.photoId,
        AddToListPopoverOrigin.GALLERY,
      ),
    });
  }

  getCustomComponents() {
    const externalRenderers = {
      ...this.itemsHelper.getCustomComponents(),
      ...(this.isCollection()
        ? {
            customHoverRenderer: this.collectionsHoverInfoElement,
          }
        : this.isFavoriteListsLoveButton &&
          this.props.customLoveCounter && {
            customHoverRenderer: this.hoverRendererWithFavoritesList,
            customInfoRenderer: this.infoRendererWithFavoritesList,
            customSlideshowInfoRenderer:
              this.slideshowInfoRendererrWithFavoritesList,
          }),
    };
    return externalRenderers;
  }

  getCustomSidebarWrapperFunc() {
    if (this.isCollection()) {
      return ({ ShopElement, pgProps, infoElement, curItem }) => (
        <>
          {this.props.isMobile && infoElement}
          <ShopSideBarWrapper
            ShopElement={ShopElement}
            pgProps={pgProps}
            collectionId={this.props.galleryId}
            isMobile={this.props.isMobile}
            isFreeArtStore={this.isFreeArtStore()}
            curItem={curItem}
          />
        </>
      );
    }
    return undefined;
  }

  getGalleryEmpty() {}

  onLoveClicked(id, origin, onFinish) {
    return this.props.onLoveClicked
      ? this.props.onLoveClicked(id, origin, onFinish)
      : super.onLoveClicked(id, origin, onFinish);
  }
}
