import React, { useState, Suspense } from 'react';
import { Tabs, TabsAlignment, TabsSkin, TabsVariant } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from './styles/sideBarShopWrapper.st.css';

const CollectionsWixComments = React.lazy(() =>
  import(
    /* webpackChunkName: "collections-comments" */ '../../../../common/components/CommentsWrapper/CommentsWrapper'
  ),
);

const FULLSCREEN_TABS = {
  DISCUSSION: 0,
  SHOP: 1,
};
export const ShopSideBarWrapper = ({
  ShopElement,
  pgProps,
  collectionId,
  isMobile,
  isFreeArtStore,
  curItem,
}) => {
  const { t } = useTranslation();
  const [currentTabIdx, setCurrentTabIndex] = useState(
    FULLSCREEN_TABS.DISCUSSION,
  );
  let tabElement;

  let itemId = '';
  if (pgProps) {
    itemId = pgProps.itemId;
  } else {
    if (curItem) {
      itemId = curItem.id;
    }
  }

  switch (currentTabIdx) {
    case FULLSCREEN_TABS.SHOP:
      tabElement = ShopElement;
      break;
    case FULLSCREEN_TABS.DISCUSSION:
      tabElement = (
        <>
          <Suspense fallback={<div />}>
            <CollectionsWixComments
              collectionId={collectionId}
              resourceId={`${collectionId}_${itemId}`}
            />
          </Suspense>
          {isMobile && <div className={classes.hiddenShop}>{ShopElement}</div>}
        </>
      );
      break;
    default:
      break;
  }
  return (
    <div
      data-hook="collection-fullscreen-side-bar-wrapper"
      className={st(classes.container, { mobile: isMobile })}
    >
      {!isFreeArtStore && (
        <Tabs
          className={st(classes.tabs, { mobile: isMobile })}
          activeTabIndex={currentTabIdx}
          alignment={TabsAlignment.left}
          items={[
            { title: t('COLLECTION_GALLERY_FULLSCREEN_TAB_DISCUSSION') },
            { title: t('COLLECTION_GALLERY_FULLSCREEN_TAB_SHOP') },
          ]}
          onTabClick={(selectedTabIndex) =>
            setCurrentTabIndex(selectedTabIndex)
          }
          skin={TabsSkin.fullUnderline}
          variant={TabsVariant.fit}
        ></Tabs>
      )}
      {tabElement}
    </div>
  );
};
