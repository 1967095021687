import React from 'react';
import {
  Text,
  TextButton,
  TextPriority,
  TextTypography,
  TextButtonPriority,
  Tooltip,
  Image,
} from 'wix-ui-tpa';
import LikeHeart from 'wix-ui-icons-common/on-stage/LikeHeart';
import LikeHeartFill from 'wix-ui-icons-common/on-stage/LikeHeartFill';
import { clickSaveCollection } from '@wix/bi-logger-photo-ugc/v2';
import { st, classes } from '../AddToListPopover.st.css';
import { useBi } from '@wix/yoshi-flow-editor';
import { LoveActions } from '../../../../../../common/LoveService';
import { resizeMediaUrl } from '../../../../../../common/helpers/itemResizer';
import { translateWithParams } from '../../../../../../common/utils';

const HEART_ICON_SIZE = '32px';
const EMPTY_COVER =
  'https://static.wixstatic.com/media/8bb438_e37c568a28414a40aaaa280f5402ac45~mv2.png';

type ItemCollection = {
  isMobile: boolean;
  setHovered: any;
  isHovered: boolean;
  collection: ItemMediaCollection;
  isInCollection: boolean;
  onClose: any;
  loveActions: LoveActions;
  t: (key: string) => string;
  biDefaultParams: object;
  currentItemId: string;
  navigator: INavigator;
};

export const ItemCollection = ({
  setHovered,
  isHovered,
  collection,
  isInCollection,
  onClose,
  loveActions,
  t,
  biDefaultParams,
  currentItemId,
  navigator,
  isMobile,
}: ItemCollection) => {
  const biLogger = useBi();
  const coverUrl =
    collection.firstItem &&
    resizeMediaUrl(
      collection.firstItem,
      collection.firstItem?.mediaUrl,
      'fill',
      52,
      52,
    );

  return (
    <div
      className={st(classes.collectionWrapper, {
        isHovered,
        mobile: isMobile,
      })}
      onMouseOver={() => setHovered(collection.mediaCollectionId)}
      onMouseLeave={() => setHovered('')}
    >
      <div className={classes.heartAndName}>
        <Tooltip
          content={t(
            `GALLERY_ADD_TO_LIST_MODAL_${
              isInCollection ? 'REMOVE' : 'ADD'
            }_TOOLTIP`,
          )}
          appendTo="window"
          placement="top"
          contentClassName={classes.tooltip}
        ></Tooltip>
        <Image width={52} height={52} src={coverUrl || EMPTY_COVER}></Image>
        <div className={classes.collectionDetailes}>
          <div className={classes.titleLine}>
            <Text className={classes.collectionName}>{collection.name}</Text>
            <div
              className={classes.loveAndViewButtons}
              data-hook="popover-heartIcon"
            >
              {isHovered && !isMobile && (
                <TextButton
                  className={classes.viewButton}
                  priority={TextButtonPriority.link}
                  onClick={() =>
                    window.open(
                      navigator.collectionPath + collection.mediaCollectionId,
                      '_blank',
                    )
                  }
                >
                  {/* TODO: change to correct key */}
                  {t('COLLECTIONS_LIST_VIEW_COLLECTION_CTA')}
                </TextButton>
              )}
              <div
                onClick={async () => {
                  onClose();

                  loveActions.switchCollectionAndLove(
                    collection.mediaCollectionId,
                    currentItemId,
                  );

                  biLogger.report(
                    clickSaveCollection({
                      ...biDefaultParams,
                      origin: 'dropdown menu',
                    }),
                  );
                }}
              >
                {isInCollection ? (
                  <LikeHeartFill
                    width={HEART_ICON_SIZE}
                    className={st(classes.heartIcon, { selected: true })}
                  />
                ) : (
                  <LikeHeart
                    className={classes.heartIcon}
                    width={HEART_ICON_SIZE}
                  />
                )}
              </div>
            </div>
          </div>
          <Text
            className={classes.collectionsItems}
            typography={TextTypography.runningText}
            priority={TextPriority.secondary}
          >
            {translateWithParams(t, 'COLLECTION_POPOVER_ITEM_INFORMATION', {
              totalItemsCount: collection.totalItemsCount,
              creatorNickname: collection.creatorNickname,
            })}
          </Text>
        </div>
      </div>
    </div>
  );
};
