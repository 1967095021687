export enum GalleryLayout {
  GRID,
  MASONRY,
}

export const calculateTextBoxHeightModeOptions = {
  AUTOMATIC: 'AUTOMATIC',
  MANUAL: 'MANUAL',
};

export const TEMPLATES_VERSION_8 = 8;

export const placements = {
  SHOW_ON_HOVER: 'SHOW_ON_HOVER',
  SHOW_BELOW: 'SHOW_BELOW',
  SHOW_ABOVE: 'SHOW_ABOVE',
  SHOW_NOT_ON_HOVER: 'SHOW_NOT_ON_HOVER',
  SHOW_ALWAYS: 'SHOW_ALWAYS',
  DONT_SHOW: 'DONT_SHOW',
};

export const ITEM_HEIGHT = 400;

export const ITEM_WIDTH = 400;

export const SETS_GALLERY_ID = 'albums-gog';

export const defaultGalleryItem = {
  id: 'default-',
  mediaUrl:
    'https://static.wixstatic.com/media/8bb438_e37c568a28414a40aaaa280f5402ac45~mv2.png',
  sortOrder: Date.now(),
  name: '',
  title: '',
  dataType: 'Photo',
  photoMetadata: {
    width: 588,
    height: 441,
    focalPoint: {
      x: 0.5,
      y: 0.5,
    },
  },
  secure: false,
  addedDate: '',
  updatedDate: '',
  mediaOwner: 'Wix',
  tags: [],
  token: '',
  galleryId: '',
};
