import React, { useState, useEffect } from 'react';
import { Dialog, Text, IconButton, IconButtonSkins } from 'wix-ui-tpa';
import { st, classes } from './GeneralModal.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import CloseIcon from '../../../../assets/icons/close.svg';

export interface IGeneralModal {
  isOpen: boolean;
  onClose?: () => void;
  content: any;
  footerContent: any;
  title: string;
  subTitle?: string;
}

export const GeneralModal = ({
  isOpen,
  onClose,
  content,
  title,
  footerContent,
  subTitle,
}: IGeneralModal) => {
  const { isMobile } = useEnvironment();
  const [animateShow, setAnimateShow] = useState(false);

  useEffect(() => {
    setAnimateShow(true);
  }, []);

  const header = (
    <div className={st(classes.header, { mobile: isMobile })}>
      <div>
        <Text className={st(classes.title, { mobile: isMobile })}>{title}</Text>
        {isMobile && (
          <IconButton
            className={st(classes.closeButton, { mobile: isMobile })}
            onClick={onClose}
            icon={<CloseIcon className={classes.closeIcon} />}
            skin={IconButtonSkins.Full}
          ></IconButton>
        )}
      </div>
    </div>
  );

  const subHeader = subTitle && (
    <div className={st(classes.subHeader, { mobile: isMobile })}>
      <Text className={st(classes.subTitle, { mobile: isMobile })}>
        {subTitle}
      </Text>
    </div>
  );

  const dialogContent = (
    <>
      {header}
      {subHeader}
      {content}
      {footerContent}
    </>
  );

  return isMobile ? (
    <div className={st(classes.mobileContainer, { open: isOpen, animateShow })}>
      {dialogContent}
    </div>
  ) : (
    <Dialog
      className={classes.root}
      isOpen={isOpen}
      onClose={onClose}
      childrenWrapperClassName={st(classes.modalContentWrapper, {
        mobile: isMobile,
      })}
      contentClassName={st(classes.modalWrapper, {
        animateShow,
        mobile: isMobile,
      })}
    >
      {dialogContent}
    </Dialog>
  );
};
