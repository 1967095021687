import React, { useState, useContext, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Toast, ToastSkin } from 'wix-ui-tpa';
import { style, classes } from './toast.st.css';

export type ToastSkin_TYPE = ToastSkin;
const ToastContext = React.createContext({} as ToastContextValue);

type ToastContextValue = {
  triggerToast: (toast: IToast) => void;
};

export interface IToast {
  content?: string;
  shouldShowCloseButton?: boolean;
  skin?: ToastSkin;
}

interface ILoalToast extends IToast {
  isShown: boolean;
}

export const ToastProvider = ({
  children,
  controllerToast,
}: {
  children: any;
  controllerToast?: IToast;
}) => {
  const [toastData, setToastData] = useState({ isShown: false } as ILoalToast);

  const showToast = (newToastData?: IToast) => {
    setToastData({ ...(newToastData || toastData), isShown: true });
  };

  const hideToast = useCallback(() => {
    if (toastData.isShown) {
      setToastData({ ...toastData, isShown: false });
    }
  }, [toastData]);

  const triggerToast = (toast: IToast) => {
    showToast(toast);
  };

  const { content, skin, shouldShowCloseButton, isShown } = toastData;

  useEffect(() => {
    controllerToast && triggerToast(controllerToast);
  }, [controllerToast]);

  useEffect(() => {
    const timer = setTimeout(() => {
      hideToast();
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [toastData.isShown, hideToast]);

  return (
    <ToastContext.Provider
      value={{
        triggerToast,
      }}
    >
      {isShown &&
        createPortal(
          <div className={classes.container}>
            <Toast
              skin={skin || ToastSkin.success}
              shouldShowCloseButton={shouldShowCloseButton}
              onClose={hideToast}
            >
              {content}
            </Toast>
          </div>,
          document.body,
        )}
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
