import React, { useState } from 'react';
import { st, classes } from './CollectionInfoModal.st.css';
import { TextField, Dropdown, TextArea, Text ,DropdownOptionProps} from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { privacyDropdownOptions } from '../../../../consts/collectionsConsts';

const maxNameChar = 100;
const maxDescriptionChar = 500;

type CollectionInfoModalFieldsProps = {
  onUpdateName: (
    name: string,
  ) => void | React.Dispatch<React.SetStateAction<string>>;
  onUpdateDescription: (
    description: string,
  ) => void | React.Dispatch<React.SetStateAction<string>>;
  onUpdatePrivacy: (
    privacyOptionId: string,
  ) => void | React.Dispatch<React.SetStateAction<string>>;
  initialName?: string;
  initialDescription?: string;
  initialPrivacyId: string;
  isMobile: boolean;
  showNoNameError: boolean;
};

export const CollectionInfoModalFields = ({
  onUpdateName,
  onUpdateDescription,
  onUpdatePrivacy,
  initialName,
  initialDescription,
  initialPrivacyId,
  isMobile,
  showNoNameError,
}: CollectionInfoModalFieldsProps) => {
  const { t } = useTranslation();
  const [nameCharCount, setNameCharCount] = useState(initialName?.length || 0);
  const [descriptionCharCount, setDescriptionCharCount] = useState(
    initialDescription?.length || 0,
  );

  const handleNameChange = (value: string) => {
    if (value.length <= maxNameChar) {
      onUpdateName(value);
      setNameCharCount(value.length);
    }
  };
  const handleDescriptionChange = (value: string) => {
    if (value.length <= maxDescriptionChar) {
      onUpdateDescription(value);
      setDescriptionCharCount(value.length);
    }
  };

  const options = privacyDropdownOptions.map((option) => {
    const { Icon, ...restOfOption } = option;
    const _option: DropdownOptionProps = { ...restOfOption };
    _option.icon = <Icon className={classes.icon} />;
    _option.subtitle = t(option.subtitle);
    return _option;
  });

  return (
    <div className={st(classes.content, { mobile: isMobile })}>
      <div className={classes.name}>
        <div className={classes.nameFieldTitle}>
          <Text>{t('COLLECTION_INFO_MODAL_NAME_FIELD_TITLE')}</Text>
          <Text>{maxNameChar - nameCharCount}</Text>
        </div>
        <TextField
          data-hook="list-name-input"
          className={st(classes.nameField, { mobile: isMobile })}
          onChange={(e) => {
            handleNameChange(e.target.value);
          }}
          placeholder={t('COLLECTION_INFO_MODAL_NAME_FIELD_PLACEHOLDER')}
          value={initialName}
          error={showNoNameError}
          errorMessage={t('COLLECTION_INFO_MODAL_NAME_FIELD_ERROR')}
          maxLength={maxNameChar}
        />
      </div>
      <div className={classes.description}>
        <div className={classes.descriptionFieldTitle}>
          <Text>{t('COLLECTION_INFO_MODAL_DESCRIPTION_FIELD_TITLE')}</Text>
          <Text>{maxDescriptionChar - descriptionCharCount}</Text>
        </div>
        <TextArea
          data-hook="list-instructions-input"
          className={st(classes.descriptionField, { mobile: isMobile })}
          onChange={(e) => {
            handleDescriptionChange(e.target.value);
          }}
          placeholder={t('COLLECTION_INFO_MODAL_DESCRIPTION_FIELD_PLACEHOLDER')}
          value={initialDescription || ''}
          ariaLabel=""
          maxLength={maxDescriptionChar}
        />
      </div>
      <div className={classes.privacy}>
        <Text className={classes.privacyFieldTitle}>
          {t('COLLECTION_INFO_MODAL_PRIVACY_FIELD_TITLE')}
        </Text>
        <Dropdown
          upgrade
          className={classes.privacyDropdown}
          onChange={(option) => onUpdatePrivacy(option.id || initialPrivacyId)}
          initialSelectedId={initialPrivacyId}
          options={options}
        />
      </div>
    </div>
  );
};
