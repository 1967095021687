import React, { useState, useEffect } from 'react';
import { st, classes } from './CollectionInfoModal.st.css';
import { useTranslation, useEnvironment, useBi } from '@wix/yoshi-flow-editor';

import { Button, ButtonPriority, DropdownOptionProps } from 'wix-ui-tpa';
import {
  privacyDropdownOptions,
  PrivacySettings,
} from '../../../../consts/collectionsConsts';
import { CollectionInfoModalFields } from './CollectionInfoModalFields';
import { GeneralModal } from '../GeneralModal/GeneralModal';
import {
  popupOpened,
  photoAlbumButtonOnClick,
} from '@wix/bi-logger-photo-ugc/v2';

type CollectionInfoModalProps = {
  onSave?: ({
    name,
    privacy_settings,
    description,
  }: CollectionDataForSave) => Promise<void>;
  onClose?: () => void;
  privacySettings?: PrivacySettings;
  name?: string;
  description?: string;
  isCreateCollection?: boolean;
  isOpen?: boolean;
  biDefaultParams?: object;
};

const privacySettingsToOptionId = {
  [PrivacySettings.Public]: '0',
  [PrivacySettings.Secret]: '1',
};

const defaultPrivacyOptionId = '0';

export const CollectionInfoModal = ({
  onSave,
  onClose,
  privacySettings,
  name,
  description,
  isCreateCollection,
  biDefaultParams,
  isOpen = true,
}: CollectionInfoModalProps) => {
  const [privacyOptionId, setPrivacyOptionId] = useState(
    privacySettings
      ? privacySettingsToOptionId[privacySettings]
      : defaultPrivacyOptionId,
  );
  const [collectionName, setCollectionName] = useState(name);
  const [collectionDescription, setCollectionDescription] = useState(
    description,
  );
  const [showNoNameError, setShowNoNameError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const logger = useBi();

  useEffect(() => {
    if (isMobile) {
      document.body.style.overflowY = 'hidden';
      return () => {
        document.body.style.overflowY = 'scroll';
      };
    }
  }, []);

  useEffect(() => {
    logger.report(
      popupOpened({ ...biDefaultParams, popupName: 'create a list' }),
    );
  }, []);

  const content = (
    <>
      <CollectionInfoModalFields
        onUpdateName={(_name: string) => {
          setCollectionName(_name);
          if (showNoNameError && _name) {
            setShowNoNameError(false);
          }
        }}
        onUpdateDescription={setCollectionDescription}
        onUpdatePrivacy={setPrivacyOptionId}
        initialName={collectionName}
        initialDescription={collectionDescription}
        initialPrivacyId={privacyOptionId}
        isMobile={isMobile}
        showNoNameError={showNoNameError}
      />
    </>
  );

  const footer = (
    <div className={st(classes.footer, { mobile: isMobile })}>
      <Button
        data-hook="collection-info-modal-secondary"
        className={st(classes.buttonInfoModal, {
          desktop: !isMobile,
          secondary: true,
        })}
        onClick={() => {
          logger.report(
            photoAlbumButtonOnClick({
              ...biDefaultParams,
              buttonName: 'cancel',
              buttonOrigin: 'createListPopup',
              screenName: 'createListPopup',
            }),
          );
          onClose && onClose();
        }}
        priority={ButtonPriority.basicSecondary}
      >
        {t('COLLECTION_INFO_MODAL_CANCEL_BUTTON_CTA')}
      </Button>
      <Button
        data-hook="collection-info-modal-primary"
        className={st(classes.buttonInfoModal, { desktop: !isMobile })}
        disabled={isDisabled}
        onClick={async () => {
          if (!collectionName) {
            setShowNoNameError(true);
          } else {
            const selectedPrivacy = privacyDropdownOptions.find(
              (option) => option.id === privacyOptionId,
            );
            if (onSave) {
              setIsDisabled(true);
              try {
                await onSave({
                  name: collectionName?.trim(),
                  privacy_settings: selectedPrivacy?.value,
                  description: collectionDescription?.trim(),
                });
              } catch (e: any) {
                console.error(e);
                setIsDisabled(false);
                // TODO: handle error
              }
            }
          }
        }}
      >
        {t(
          `COLLECTION_INFO_MODAL_${
            isCreateCollection ? 'CREATE' : 'SAVE'
          }_BUTTON_CTA`,
        )}
      </Button>
    </div>
  );

  return (
    <GeneralModal
      title={t(
        `COLLECTION_INFO_MODAL_TITLE_${isCreateCollection ? 'CREATE' : 'EDIT'}`,
      )}
      content={content}
      footerContent={footer}
      isOpen={isOpen}
      onClose={() => {
        onClose && onClose();
        logger.report(
          photoAlbumButtonOnClick({
            ...biDefaultParams,
            buttonName: 'exit',
            buttonOrigin: 'create list popup',
          }),
        );
      }}
    />
  );
};
