import React, { useRef } from 'react';
import { st, classes } from '../AddToListPopover.st.css';
import { LoveActions } from '../../../../../../common/LoveService';
import { useBi } from '@wix/yoshi-flow-editor';
import { popupOpened, createCollection } from '@wix/bi-logger-photo-ugc/v2';
import { ItemCollection } from './ItemColletion';
import Add from 'wix-ui-icons-common/Add';
import { TextButton, Text, TextButtonPriority } from 'wix-ui-tpa';

type ItemCollectionsListDesktopProps = {
  itemCollections: ItemMediaCollection[];
  hoveredItemId: string;
  setHovered: (id: string) => void;
  onClose: () => void;
  onCreate: () => void;
  loveActions: LoveActions;
  t: (key: string) => string;
  currentItemId: string;
  navigator: INavigator;
  biDefaultParams: object;
  className?: string;
};

export const ItemCollectionsListDesktop = ({
  itemCollections,
  hoveredItemId,
  setHovered,
  onClose,
  onCreate,
  loveActions,
  currentItemId,
  t,
  navigator,
  biDefaultParams,
  className,
}: ItemCollectionsListDesktopProps) => {
  const logger = useBi();
  const menu = useRef<any>();

  return (
    <div
      className={st(classes.popoverContainer, {}, className)}
      ref={menu}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={classes.header}>
        <Text className={classes.title}>
          {t('GALLERY_ADD_TO_LIST_MODAL_TITLE')}
        </Text>
        <Text className={classes.subtitle}>
          {t('GALLERY_ADD_TO_LIST_MODAL_SUBTITLE')}
        </Text>
      </div>
      <div className={st(classes.collectionsList)}>
        {itemCollections &&
          itemCollections.map((collection, idx) => {
            const isHovered = hoveredItemId === collection.mediaCollectionId;
            const isInCollection = collection.isItemInCollection;
            return (
              <ItemCollection
                setHovered={setHovered}
                isHovered={isHovered}
                collection={collection}
                isInCollection={isInCollection}
                onClose={onClose}
                loveActions={loveActions}
                t={t}
                biDefaultParams={biDefaultParams}
                currentItemId={currentItemId}
                navigator={navigator}
                isMobile={false}
              />
            );
          })}
      </div>
      <div className={st(classes.footer)}>
        <TextButton
          prefixIcon={<Add />}
          priority={TextButtonPriority.secondary}
          className={classes.createButton}
          onClick={() => {
            onCreate();
            logger.report(
              popupOpened({
                ...biDefaultParams,
                popupName: 'create a list',
              }),
            );
            logger.report(
              createCollection({
                ...biDefaultParams,
                origin: 'Live site heart dropdown',
              }),
            );
          }}
        >
          {t('GALLERY_ADD_TO_LIST_MODAL_CREATE')}
        </TextButton>
      </div>
    </div>
  );
};
