import { translationUtils } from '@wix/photography-client-lib';

const galleryKeys = [
  'Gallery_ArtStore_UOU_Add_To_Cart_Button',
  'Gallery _CartView_Cart_Label',
  'Gallery_ArtStore_UOU_Continue_Checkout_Label',
  'Gallery_ArtStore_UoU_Checkout_PopUp_NoPaymentsConnected_Title',
  'Gallery_ArtStore_UoU_Checkout_PopUp_NoPaymentsConnected_Text',
  'Gallery_ArtStore_UoU_Checkout_PopUp_NoPaymentsConnected_CTA',
];

export const getPgTranslations = (t: (k: string) => string) => {
  const galleryTranslations = galleryKeys.reduce(
    (acc, key) => ({ ...acc, [key]: t(key) }),
    {},
  );
  translationUtils.setTranslations(galleryTranslations);
  return galleryTranslations;
};
