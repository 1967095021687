import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import {
  translationUtils,
  experimentsWrapper,
} from '@wix/photography-client-lib';
import '../../../styles/CommentsTheming.global.scss';
import '../../../styles/CollectionsTheming.css';
import '../../../styles/fullscreenCollectionsStyles.global.scss';
import GalleryWrapper from './GalleryWrapper/GalleryWrapper';
import {
  GALLERY_TYPES,
  GALLERY_TYPE,
} from '../../../common/helpers/galleryTypes';
import { style, classes } from './index.st.css';
import { RootPropsProvider } from '../../../common/context/RootPropsContext/RootPropsContext';
import { TPAComponentsProvider } from 'wix-ui-tpa';

import cx from 'classnames';
import { getPgTranslations } from '../helpers/translationsHelper';
import { LOVE_BUTTON_ACTION, Status } from '../../../consts/collectionsConsts';
import pathParse from 'path-parse';
import {
  isNotListMember,
  isNotLoggedIn,
} from '../../../common/helpers/permissions';
import './globalCss.global.scss';
import { useWorkerPromise } from '../../../common/hooks/useWorkerPromise';
import { Actions } from '../../Collection/types';
import {
  ToastProvider,
  IToast,
} from '../../../common/context/ToastContext/ToastContext';
import { LoveActions } from '../../../common/LoveService';

import { useFavoritesLists } from './Favorites/useFavoritesLists';
import { pauseHeightChanges } from '@wix/yoshi-flow-editor/wix-height-updater';

type GalleryWidgetProps = {
  pgTranslations: object;
  visible: boolean;
  galleryWixCodeApi: GalleryWixCodeApiFunctions;
  galleryId: string;
  galleryType: GALLERY_TYPE;
  instance: string;
  instanceId: string;
  items?: MediaItem[];
  removeItem: (itemId: string) => void;
  userStatus: Status;
  triggerJoinModal: () => void;
  actions: Actions;
  promiseResult: string;
  currentItemCollections?: ItemMediaCollection[];

  navigator: INavigator;
  promiseResultData: any;
  user: { id?: string; role?: string };
  loveButtonAction: LoveButtonAction;
  actionCallbacks: Callbacks;
  loveActions: LoveActions;
  controllerToast?: IToast;
  sets: AlbumSet[];
  onRefuseLoginRequest: () => void;
  showSignUpModal?: { res: boolean };
} & WidgetProps & { host: any };

export default (props: GalleryWidgetProps) => {
  const {
    visible,
    galleryWixCodeApi,
    userStatus,
    galleryType,
    triggerJoinModal,
    actions: collectionActions,
    promiseResult,
    navigator,
    promiseResultData,
    instanceId,
    user,
    galleryId,
    items,
    loveActions,
    currentItemCollections,
    currentCollectionName,
    loggedIn,
  } = props;

  // needed in order to be able to resize the gallery height in the editor
  // if ((props.host?.viewMode as string).toLocaleLowerCase() === 'editor') {
  //   return <div />;
  // }

  if (!galleryId) {
    // Means that something has crashed in controller
    return <div />;
  }

  const [showGallery, setShowGallery] = useState(visible);
  const { experiments } = useExperiments();
  const { t } = useTranslation();

  const { isMobile, isPreview, isSSR, isEditor } = useEnvironment();

  const isLoveAddsToFavorites =
    props.galleryType === GALLERY_TYPES.SET &&
    LOVE_BUTTON_ACTION.FAVORITE_LISTS === props.loveButtonAction;

  const onMessage = ({ data }: any = {}) => {
    if (data.type === 'refreshData') {
      let setId = galleryId;
      if (galleryId === 'yoursitename') {
        // if albums builder we can't determine the setId in controller
        setId = pathParse(window.location.pathname).base;
      }
      const styleParams = data.data?.albumData?.styleParams;
      const currentSet = data.data?.sets?.find((set: any) => set.id === setId);
      galleryWixCodeApi.setStyleParams(styleParams);
      galleryWixCodeApi.setItems(currentSet?.items);
    }
  };

  const workerActions = useWorkerPromise({
    actions: collectionActions,
    promiseResult,
    promiseResultData,
  });

  useEffect(() => {
    experimentsWrapper.setExperiments(experiments.all());
    if (props.galleryType === GALLERY_TYPES.SET && isPreview) {
      window.addEventListener('message', onMessage);
      return () => {
        window.removeEventListener('message', onMessage);
      };
    }
  }, []);

  useEffect(() => {
    if (isEditor) {
      pauseHeightChanges();
    }
  }, []);

  useEffect(() => {
    // TODO: hack for draft albums in builder not having setId in location. find a better way
    if (isPreview && galleryId === 'yoursitename') {
      const pathBase = pathParse(window.location.href).base;
      const setId = pathBase.slice(0, pathBase.indexOf('?'));
      if (setId) {
        const currentSet = props.sets?.find((set: any) => set.id === setId);
        currentSet && galleryWixCodeApi.setItems(currentSet?.items);
      }
    }
  }, []);

  useEffect(() => {
    if (typeof visible !== 'undefined') {
      setShowGallery(visible);
    }
  }, [visible]);
  const actionPrecondition = (e?: Event) => {
    if (isNotListMember(userStatus)) {
      e?.stopPropagation && e.stopPropagation();
      e?.preventDefault && e.preventDefault();
      if (isNotLoggedIn(userStatus)) {
        collectionActions.JOIN(JSON.stringify({ origin: 'download image' }));
      } else {
        triggerJoinModal();
      }
      return false;
    }
    return true;
  };

  const translations = getPgTranslations(t);
  const galleryContainerClassName = 'collections-gallery-wrapper';
  const galleryTypeClass =
    galleryType === GALLERY_TYPES.LIST ? 'collection-gallery' : 'set-gallery';

  const isInBuilder =
    (props.host.viewMode as string).toLocaleLowerCase() === 'preview';

  const biDefaultParams = {
    albumID: props.albumData?.settings?.id,
    instanceId,
    visitorId: user?.id,
    role: user?.role,
  };

  const {
    onLoveClicked,
    getItemFavoritesListDropdown,
    getFullscreenFavoritesListDropdown,
    favoritesListsElements,
  } = useFavoritesLists({
    isActive: isLoveAddsToFavorites && !isSSR,
    loveActions,
    getLoginPromise: workerActions.LOG_IN,
    navigator,
    compId: props.host.id,
    currentCollectionName,
    currentItemCollections,
    loggedIn,
    biDefaultParams,
    galleryId,
  });

  const { actions, ...propsWithoutActions } = props;
  return (
    <RootPropsProvider
      value={{
        ...props,
        userStatus,
      }}
    >
      <ToastProvider controllerToast={props.controllerToast}>
        <TPAComponentsProvider value={{ mobile: isMobile, rtl: true }}>
          <div
            className={style(
              cx(classes.root, galleryContainerClassName, galleryTypeClass),
              {
                visible: showGallery,
                mobile: isMobile,
                noItems: items?.length === 0,
              },
            )}
          >
            <GalleryWrapper
              queryParams={{}}
              {...propsWithoutActions}
              isMobile={isMobile}
              removeItemAndUpdateState={props.removeItem}
              galleryActionPrecondition={actionPrecondition}
              pgTranslations={{ ...props.pgTranslations }}
              translations={translations}
              {...(isInBuilder && { viewMode: 'EDITOR' })}
              onLoveClicked={onLoveClicked}
              customLoveCounter={getItemFavoritesListDropdown}
              fullscreenCustomLoveCounter={getFullscreenFavoritesListDropdown}
              cssBaseUrl={false}
            />
          </div>
        </TPAComponentsProvider>
        {favoritesListsElements}
      </ToastProvider>
    </RootPropsProvider>
  );
};
