import Private from 'wix-ui-icons-common/Hidden';
import PublicIcon from 'wix-ui-icons-common/Public';

export enum Status {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  NOT_MEMBER = 'NOT_MEMBER',
  UNDEFINED = 'UNDEFINED',
}

export enum ModalType {
  EDIT_INFO,
  DELETE,
  LEAVE,
  JOIN,
  INVITE,
  PRIVATE_PROFILE,
  REQUIRE_JOIN,
  CREATE,
  REMOVE_MEMBER,
  SIGN_UP,
}

export enum ActionType {
  LEAVE = 'LEAVE',
  DELETE = 'DELETE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  JOIN = 'JOIN',
  REMOVE_MEMBER = 'REMOVE_MEMBER',
  ADD_ITEMS = 'ADD_ITEMS',
  REMOVE_ITEMS = 'REMOVE_ITEMS',
  UPDATE_ITEMS = 'UPDATE_ITEMS',
  DOWNLOAD = 'DOWNLOAD',
  LOG_IN = 'LOG_IN',
}

export function getStatusTitleKey(status?: Status) {
  return status ? `COLLECTIONS_MEMBER_ROLE_${status}` : '';
}

export enum PrivacySettings {
  Secret = 'Secret',
  Public = 'Public',
}

export const privacySettingsData = {
  [PrivacySettings.Public]: {
    title: getPrivacyText(PrivacySettings.Public),
    description: getPrivacyText(PrivacySettings.Public) + '_DESC',
    Icon: PublicIcon,
  },
  [PrivacySettings.Secret]: {
    title: getPrivacyText(PrivacySettings.Secret),
    description: getPrivacyText(PrivacySettings.Secret) + '_DESC',
    Icon: Private,
  },
};

export function getPrivacyText(privacyType?: PrivacySettings) {
  return privacyType
    ? `COLLECTIONS_PRIVACY_SETTING_${privacyType.toUpperCase()}`
    : '';
}

function getPrivacySubtitle(privacyType?: PrivacySettings) {
  return privacyType
    ? `COLLECTIONS_PRIVACY_SETTING_${privacyType.toUpperCase()}_DESC`
    : '';
}

export const privacyDropdownOptions = [
  {
    id: '0',
    value: PrivacySettings.Public,
    isSelectable: true,
    Icon: PublicIcon,
    subtitle: getPrivacySubtitle(PrivacySettings.Public),
  },
  {
    id: '1',
    value: PrivacySettings.Secret,
    isSelectable: true,
    Icon: Private,
    subtitle: getPrivacySubtitle(PrivacySettings.Secret),
  },
];

export const LOVE_BUTTON_ACTION = {
  FAVORITE_LISTS: 'favoritesLists',
  LIKE_COUNTER: 'likeCounter',
};

export const enum PAGE {
  SETS = 'sets',
  SET = 'set',
  LISTS = 'lists',
  LIST = 'list',
}

export enum WidgetsName {
  CartIcon = 'cartIcon',
  Gallery = 'gallery',
  HeartIcon = 'headerIcon',
}

export enum AddToListPopoverOrigin {
  GALLERY = 'gallery',
  FULLSCREEN = 'fullscreen',
}
