import { Status } from '../../consts/collectionsConsts';

export const isNotListMember = (status?: Status) =>
  !status || status === Status.NOT_MEMBER || status === Status.UNDEFINED;

export const isNotLoggedIn = (status?: Status) =>
  !status || status === Status.UNDEFINED;

export const isListMember = (status?: Status) =>
  status === Status.MEMBER || status === Status.ADMIN;

export const isListAdmin = (status?: Status) => status === Status.ADMIN;
