import React, { useCallback, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { love, clickSaveCollection } from '@wix/bi-logger-photo-ugc/v2';
import { LoveActions } from '../../../../common/LoveService';
import { AddToListPopover } from './AddToListPopover/AddToListPopover';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import { GalleryCreateListModal } from './GalleryCreateListModal/GalleryCreateListModal';
import { FavoritesListDropdown } from './FavoritesListsDropdown/FavoritesListsDropdown';
import { AddToListPopoverOrigin } from '../../../../consts/collectionsConsts';

export const useFavoritesLists = ({
  isActive,
  loggedIn,
  getLoginPromise,
  biDefaultParams,
  galleryId,
  currentCollectionName,
  loveActions,
  currentItemCollections,
  compId,
  navigator,
}: {
  galleryId: string;
  currentItemCollections?: ItemMediaCollection[];
  navigator: INavigator;
  loveActions: LoveActions;
  biDefaultParams: any;
  currentCollectionName: string;
  compId: string;
  loggedIn: boolean;
  getLoginPromise: (data: string) => Promise<any>;
  collectionsFetched?: boolean;
  isActive: boolean;
}) => {
  const { isMobile } = useEnvironment();
  const biLogger = useBi();

  const [isItemAddToListOpen, setItemAddToListOpen] = useState(false);
  const [isFullscreenAddToListOpen, setFullscreenAddToListOpen] =
    useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [waitingForFetchCollections, setWaitingForFetchCollections] =
    useState(false);
  const [currentItemId, setCurrentItemId] = useState('');

  useEffect(() => {
    setWaitingForFetchCollections(false);
  }, [currentItemCollections]);

  if (!isActive) {
    return {
      onLoveClicked: undefined,
      getItemFavoritesListDropdown: undefined,
      getFullscreenFavoritesListDropdown: undefined,
      favoritesListsElements: undefined,
    };
  }

  const noCollections =
    currentItemCollections && currentItemCollections.length === 0;

  const biDefaultParamsForLove = {
    ...biDefaultParams,
    origin: noCollections
      ? 'Live site heart on image'
      : 'Live site heart dropdown',
  };

  const fetchCollections = (id: string) => {
    setWaitingForFetchCollections(true);
    setCurrentItemId(id);
    loveActions.fetchItemCollections(id);
  };

  const openAddToList = async (id: string, origin: string) => {
    if (!loggedIn) {
      try {
        const logInPromise = getLoginPromise(
          JSON.stringify({ showInfoModal: true, origin: 'add image' }),
        );
        await logInPromise;
      } catch (e: any) {
        console.error('Not logged in', e);
        return;
      }
    } else {
      fetchCollections(id);
      switch (origin) {
        case AddToListPopoverOrigin.GALLERY:
          setItemAddToListOpen(true);
          break;
        case AddToListPopoverOrigin.FULLSCREEN:
          setFullscreenAddToListOpen(true);
          break;
      }
    }
  };

  const closeAddToList = () => {
    setItemAddToListOpen(false);
    setFullscreenAddToListOpen(false);
  };

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };
  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  const onLoveClicked = async (id: string, origin: string) => {
    if (typeof id !== 'string') {
      return;
    }
    biLogger.report(
      love({
        ...biDefaultParams,
        galleryType: 'photo album',
        gallery_id: galleryId,
      }),
    );
    if (isMobile) {
      openAddToList(id, origin);
    } else {
      if (!currentCollectionName) {
        await openAddToList(id, origin);
        loveActions.init(id);
      } else {
        loveActions.toggleLove(id);
        biLogger.report(
          clickSaveCollection({
            ...biDefaultParams,
            origin: 'love button',
          }),
        );
      }
    }
  };
  const getItemFavoritesListDropdown =
    currentCollectionName && !isMobile
      ? (id: string, origin: string) => {
          return (
            <FavoritesListDropdown
              isAddToListOpen={
                !waitingForFetchCollections && isItemAddToListOpen
              }
              origin={origin}
              openAddToList={openAddToList}
              navigator={navigator}
              closeAddToList={closeAddToList}
              biDefaultParams={biDefaultParams}
              currentItemCollections={currentItemCollections}
              currentCollectionName={currentCollectionName}
              openCreateModal={openCreateModal}
              loveActions={loveActions}
              currentItemId={currentItemId}
              compId={compId}
              id={id}
            />
          );
        }
      : undefined;

  const getFullscreenFavoritesListDropdown =
    currentCollectionName && !isMobile
      ? (id: string, origin: string) => {
          return (
            <FavoritesListDropdown
              isAddToListOpen={
                !waitingForFetchCollections && isFullscreenAddToListOpen
              }
              origin={origin}
              openAddToList={openAddToList}
              navigator={navigator}
              closeAddToList={closeAddToList}
              biDefaultParams={biDefaultParams}
              currentItemCollections={currentItemCollections}
              currentCollectionName={currentCollectionName}
              openCreateModal={openCreateModal}
              loveActions={loveActions}
              currentItemId={currentItemId}
              compId={compId}
              id={id}
            />
          );
        }
      : undefined;

  const shouldOpenCreateModal =
    !waitingForFetchCollections &&
    (((isItemAddToListOpen || isFullscreenAddToListOpen) && noCollections) ||
      isCreateModalOpen);

  // Used for mobile
  const mobileAddToListPopoverPortalElement =
    document.getElementsByClassName('fullscreen-comp-wrapper')[0] ||
    document.getElementsByClassName('pro-gallery-component-wrapper')[0] ||
    document.body;

  const favoritesListsElements = (
    <>
      {/* Add to list modal, Used only for mobile */}
      {isMobile &&
        ReactDOM.createPortal(
          <AddToListPopover
            biDefaultParams={biDefaultParams}
            isOpen={isItemAddToListOpen}
            onClose={closeAddToList}
            itemCollections={currentItemCollections}
            currentItemId={currentItemId}
            navigator={navigator}
            loveActions={loveActions}
            onCreate={openCreateModal}
            className={compId}
          />,
          mobileAddToListPopoverPortalElement,
        )}

      {shouldOpenCreateModal &&
        ReactDOM.createPortal(
          <GalleryCreateListModal
            compId={compId}
            biDefaultParamsForLove={biDefaultParamsForLove}
            onClose={() => {
              closeAddToList();
              closeCreateModal();
            }}
            loveActions={loveActions}
            currentItemId={currentItemId}
          />,
          document.body,
        )}
    </>
  );

  return {
    onLoveClicked,
    getItemFavoritesListDropdown,
    getFullscreenFavoritesListDropdown,
    favoritesListsElements,
  };
};
