import { TEMPLATES_VERSION_8 } from '../consts/galleryConsts';
import { LOVE_BUTTON_ACTION } from '../consts/collectionsConsts';
import { isListOrListsPage } from './headerUtils';

const trimUntilSlash = (str: string, index: number) =>
  str.split('/').slice(0, index).join('/');

export const getBaseUrl = (isViewer: boolean): string => {
  const baseUrl = isViewer ? '' : 'https://www.wix.com/_api';
  return baseUrl;
};

export const getRootPath = (baseUrl: string) => {
  let baseUrlPartsLength = 3;
  // is free site url
  if (
    baseUrl.includes('wixsite') ||
    baseUrl.includes('editorx.io') ||
    baseUrl.includes('wixstudio.io')
  ) {
    baseUrlPartsLength = 4;
  }

  return trimUntilSlash(baseUrl, baseUrlPartsLength);
};

export const escapeSpecialChars = (srcString: string) =>
  encodeURIComponent(srcString).replace(/'/g, '%27');

export const translateWithParams = (t: Function, tKey: string, tObj: any) => {
  for (const key in tObj) {
    tObj[key] = escapeSpecialChars(tObj[key]);
  }
  return decodeURIComponent(t(tKey, tObj));
};

export const findCoverSrcById = (gallerys: any, id: string) => {
  let galleryItem;
  gallerys.map(
    (gallery: any) =>
      (galleryItem = gallery.items.find((item: any) => item.id === id)),
  );
  return galleryItem;
};

export const isAlbumTemplateV8 = (albumData: AlbumSettings) => {
  return albumData.template.version >= TEMPLATES_VERSION_8;
};

export const shouldAllowSelections = (
  albumData: AlbumSettings,
  currentPageName?: string,
) => {
  const {
    styleParams: { loveButton },
    loveButtonAction,
  } = albumData;

  return (
    isListOrListsPage(currentPageName) ||
    (loveButton && loveButtonAction === LOVE_BUTTON_ACTION.FAVORITE_LISTS)
  );
};
