import React, { useState, useEffect, Suspense } from 'react';
import { EXTERNAL_INFO_TYPE } from '@wix/pro-gallery-info-element'; // TODO: consider dynamic impotr
import { withItemMenu } from './withItemMenu';
import { useTranslation } from '@wix/yoshi-flow-editor';

const CollectionInfoElement = React.lazy(
  () =>
    import(
      /* webpackChunkName: "collections-info-element" */ '../CollectionInfoElement/CollectionInfoElement'
    ),
);

export const ItemHover = ({
  pgItemProps,
  InfoElement,
  commentsCount,
  processPgItemPropsForInfoElement,
  removeItemAndUpdateState,
  galleryActionPrecondition,
  locked,
  compId,
}: {
  pgItemProps: any;
  collectionId: string;
  InfoElement: any;
  commentsCount: number;
  processPgItemPropsForInfoElement: any;
  removeItemAndUpdateState: (itemId: string) => void;
  galleryActionPrecondition: (e?: Event) => Promise<boolean>;
  locked?: boolean;
  compId: string;
}) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = (e?: any) => {
    e && e.stopPropagation();
    e && e.preventDefault();
    setIsMenuOpen(false);
  };
  const menuOptions = [
    {
      content: t('COLLECTION_GALLERY_MENU_ADD_COMMENT'),
      onClick: (e: any) => {},
    },
  ];
  if (!locked) {
    menuOptions.push({
      content: t('COLLECTION_GALLERY_MENU_REMOVE_ITEM'),
      onClick: (e?: any) => {
        e && e.stopPropagation();
        e && e.preventDefault();
        removeItemAndUpdateState(pgItemProps.id);
      },
    });
  }

  useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }

    const listener = ({ code }: KeyboardEvent) => {
      if (['Escape', 'Tab'].includes(code) && isMenuOpen) {
        closeMenu();
      }
    };

    document.addEventListener('keydown', listener, true);

    return () => document.removeEventListener('keydown', listener, true);
  }, [isMenuOpen, closeMenu]);

  return (
    <Suspense fallback={<div />}>
      <CollectionInfoElement
        {...processPgItemPropsForInfoElement(
          EXTERNAL_INFO_TYPE.COLLECTIONS_HOVER,
          pgItemProps,
        )}
        commentsCounter={commentsCount}
        onDotsClick={() => setIsMenuOpen(true)}
        dotsWrapper={withItemMenu}
        closeMenu={closeMenu}
        isMenuOpen={isMenuOpen}
        menuOptions={menuOptions}
        checkSharePrecondition={galleryActionPrecondition}
        checkDownloadPrecondition={galleryActionPrecondition}
        hideDownload={locked}
        compId={compId}
        isHorizontal={!pgItemProps.options.isVertical}
      />
    </Suspense>
  );
};
