import React, { useRef } from 'react';
import { st, classes } from './FavoritesListsDropdown.st.css';
import cx from 'classnames';
import { Popover, Text } from 'wix-ui-tpa';
import { ChevronDown } from 'wix-ui-icons-common';
import { useBi } from '@wix/yoshi-flow-editor';
import { AddToListPopover } from '../AddToListPopover/AddToListPopover';
import { LoveActions } from '../../../../../common/LoveService';
import { dropDownMenuOnPhoto } from '@wix/bi-logger-photo-ugc/v2';

type FavoritesListDropdownProps = {
  isAddToListOpen: boolean;
  closeAddToList: () => void;
  openAddToList: (id: string, origin: string) => void;
  openCreateModal: () => void;
  biDefaultParams: any;
  currentItemCollections?: ItemMediaCollection[];
  currentCollectionName: string;
  navigator: INavigator;
  loveActions: LoveActions;
  currentItemId: string;
  compId: string;
  id: string;
  origin: string;
};

export const FavoritesListDropdown = ({
  isAddToListOpen,
  closeAddToList,
  openAddToList,
  biDefaultParams,
  navigator,
  currentItemCollections,
  currentCollectionName,
  openCreateModal,
  loveActions,
  currentItemId,
  compId,
  id,
  origin,
}: FavoritesListDropdownProps) => {
  const biLogger = useBi();
  const clickOrigin = useRef(origin);

  const onDropdownClicked = async (
    e: React.MouseEvent<HTMLDivElement>,
    _id: string,
    currentOrigin: string,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    clickOrigin.current = currentOrigin;
    openAddToList(_id, currentOrigin);
    biLogger.report(dropDownMenuOnPhoto(biDefaultParams));
  };

  return (
    <Popover
      tabIndex={-1}
      shown={
        isAddToListOpen &&
        clickOrigin.current === origin &&
        id === currentItemId
      }
      placement="top-start"
      className={classes.popover}
      // triggerAction=""
      showArrow={false}
      appendTo="window"
      contentClassName={classes.popoverContent}
      onEscPress={closeAddToList}
      onTabOut={closeAddToList}
      onClickOutside={closeAddToList}
      width={360}
      maxWidth={360}
      wiredToSiteColors={false}
    >
      <Popover.Element>
        <div
          className={cx([
            'info-member',
            'info-element-social-love-count',
            'dropdown-container',
            st(classes.dropDownContainer, {
              isFullscreen: origin === 'fullscreen',
            }),
          ])}
          onClick={(e) => {
            onDropdownClicked && onDropdownClicked(e, id, origin);
          }}
        >
          <Text className={`currentFavoriteList ${origin}`}>
            {currentCollectionName}
          </Text>
          <ChevronDown className={`currentFavoriteListIcon ${origin}`} />
        </div>
      </Popover.Element>
      <Popover.Content>
        <AddToListPopover
          biDefaultParams={biDefaultParams}
          isOpen={
            currentItemCollections ? currentItemCollections.length > 0 : false
          }
          onClose={closeAddToList}
          itemCollections={currentItemCollections}
          currentItemId={currentItemId}
          navigator={navigator}
          loveActions={loveActions}
          onCreate={openCreateModal}
          className={compId}
        />
      </Popover.Content>
    </Popover>
  );
};
