import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { popupOpened, createCollection } from '@wix/bi-logger-photo-ugc/v2';
import { LoveActions } from '../../../../../common/LoveService';
import { ItemCollectionsList } from './ItemCollectionsList/ItemCollectionsList';

export enum AddToListComponentTypes {
  MODAL = 'modal',
  POPOVER = 'popover',
}

export interface IAddToListPopover {
  isOpen: boolean;
  itemCollections?: ItemMediaCollection[];
  onClose: () => void;
  currentItemId: string;
  loveActions: LoveActions;
  navigator: INavigator;
  biDefaultParams: {
    albumId: string;
    instanceId: string;
    visitorId: string;
  };
  onCreate: () => void;
  className?: string;
}

export const AddToListPopover = ({
  isOpen,
  onClose,
  itemCollections,
  loveActions,
  currentItemId,
  biDefaultParams,
  navigator,
  onCreate,
  className,
}: // createCollection,
IAddToListPopover) => {
  const [currentHoveredCollectionId, setCurrentHoveredCollectionId] = useState(
    '',
  );

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const logger = useBi();

  useEffect(() => {
    isOpen &&
      logger.report(
        popupOpened({
          ...biDefaultParams,
          popupName: 'add to favorites list',
        }),
      );
  }, [isOpen]);

  return isOpen ? (
    <div>
      {itemCollections && itemCollections.length > 0 && (
        <ItemCollectionsList
          isMobile={isMobile}
          itemCollections={itemCollections}
          hoveredItemId={currentHoveredCollectionId}
          setHovered={setCurrentHoveredCollectionId}
          onClose={onClose}
          loveActions={loveActions}
          currentItemId={currentItemId}
          t={t}
          navigator={navigator}
          biDefaultParams={biDefaultParams}
          onCreate={onCreate}
          className={className}
        />
      )}
    </div>
  ) : (
    <div />
  );
};
