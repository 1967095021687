export type GALLERY_TYPE = 'set' | 'list' | 'sets';

export const GALLERY_TYPES = {
  SET: 'set',
  SETS: 'sets',
  LIST: 'list',
  DEMO: 'demo',
};

export const getGalleryType = (currentPage: any) => {
  const currentPageName = currentPage?.name?.toLowerCase();
  switch (currentPageName) {
    case 'list':
      return GALLERY_TYPES.LIST;
    case 'set':
      return GALLERY_TYPES.SET;
    case 'sets':
      return GALLERY_TYPES.SETS;
    default:
      return '';
  }
};
