import React from 'react';
import { ItemCollectionsListDesktop } from './ItemCollectionsListDesktop';
import { ItemCollectionsListMobile } from './ItemCollectionsListMobile';
import { LoveActions } from '../../../../../../common/LoveService';

type ItemCollectionsListProps = {
  isMobile: boolean;
  itemCollections: ItemMediaCollection[];
  hoveredItemId: string;
  setHovered: (id: string) => void;
  onClose: () => void;
  onCreate: () => void;
  loveActions: LoveActions;
  t: (key: string) => string;
  currentItemId: string;
  navigator: INavigator;
  biDefaultParams: object;
  className?: string;
};

export const ItemCollectionsList = ({
  isMobile,
  itemCollections,
  hoveredItemId,
  setHovered,
  onClose,
  loveActions,
  currentItemId,
  t,
  navigator,
  biDefaultParams,
  onCreate,
  className,
}: ItemCollectionsListProps) => {
  const collectionsListProps = {
    itemCollections,
    hoveredItemId,
    setHovered,
    onClose,
    loveActions,
    currentItemId,
    t,
    navigator,
    biDefaultParams,
    onCreate,
    className,
  };

  const popover = isMobile ? (
    <ItemCollectionsListMobile {...collectionsListProps} />
  ) : (
    <ItemCollectionsListDesktop {...collectionsListProps} />
  );

  return popover;
};
